import revive_payload_client_vWl38pui_ocIRzLi3kQ9g0wM0LpuEHWTUG51HsyK6KQ from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_1b86500d3e0cde722b44692c5c88f13a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Ia_ikyVwK6bdcYV898IXhMbIn17o2aCIyByJTv3dhgc from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_1b86500d3e0cde722b44692c5c88f13a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UFWcO4QMaGtvliH8sQOeHGB_ksjsU48XGiMtE0qLScA from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_1b86500d3e0cde722b44692c5c88f13a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5v0ERLoRbibovwIk41ADAo2dBKpvmSdPSXVuIKnYTwo from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_1b86500d3e0cde722b44692c5c88f13a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HVA7qbtporccA9idQukI6JBn0_yl6gmRgyolx05JIhE from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_1b86500d3e0cde722b44692c5c88f13a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9FPi4Ns8pUz9OoiCMeax51o771Nh25Io5aY_pHx3x8I from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_1b86500d3e0cde722b44692c5c88f13a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5tqHIbw666Qj0TsrNOvgsIAPRodd9hHyJGfXqt_aJEk from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_1b86500d3e0cde722b44692c5c88f13a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_0mTnHAuPmRaHKIJWX55HIKWlJRRDDqYUpz5sRyA9yNU from "/app/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@22.13.14_db0@0.3.1_eslint@9.23.0_jiti@2.4_1b86500d3e0cde722b44692c5c88f13a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_IcqTfuPcpubBHF4Z7IxPm_an8gb61d8kNiZ_5W4COK4 from "/app/node_modules/.pnpm/nuxt-easy-lightbox@1.0.2_magicast@0.3.5_vue@3.5.13_typescript@4.9.5_/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_59TH8eaI4Mns3gICQyhT4roDMBDrIf4FTXsEk_UYKc0 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_eba04bb02946dfe52e04e20d35229d1e/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_m6GX2dV3nbzvuaumvremy2nLUwAma7BvhvTWEMLEzEo from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_eba04bb02946dfe52e04e20d35229d1e/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_f2Q6JNOMlk_4XKFT3kDZt9YQGzd9vIJLu4ZeSMyCqps from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_eba04bb02946dfe52e04e20d35229d1e/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import ssg_detect_knOYSFrV0sEcIFAOc3IYaJ_Bnv4DRNSVYu_yMsIrjzc from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_ro_eba04bb02946dfe52e04e20d35229d1e/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_vWl38pui_ocIRzLi3kQ9g0wM0LpuEHWTUG51HsyK6KQ,
  unhead_Ia_ikyVwK6bdcYV898IXhMbIn17o2aCIyByJTv3dhgc,
  router_UFWcO4QMaGtvliH8sQOeHGB_ksjsU48XGiMtE0qLScA,
  payload_client_5v0ERLoRbibovwIk41ADAo2dBKpvmSdPSXVuIKnYTwo,
  navigation_repaint_client_HVA7qbtporccA9idQukI6JBn0_yl6gmRgyolx05JIhE,
  check_outdated_build_client_9FPi4Ns8pUz9OoiCMeax51o771Nh25Io5aY_pHx3x8I,
  chunk_reload_client_5tqHIbw666Qj0TsrNOvgsIAPRodd9hHyJGfXqt_aJEk,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_0mTnHAuPmRaHKIJWX55HIKWlJRRDDqYUpz5sRyA9yNU,
  plugin_IcqTfuPcpubBHF4Z7IxPm_an8gb61d8kNiZ_5W4COK4,
  switch_locale_path_ssr_59TH8eaI4Mns3gICQyhT4roDMBDrIf4FTXsEk_UYKc0,
  route_locale_detect_m6GX2dV3nbzvuaumvremy2nLUwAma7BvhvTWEMLEzEo,
  i18n_f2Q6JNOMlk_4XKFT3kDZt9YQGzd9vIJLu4ZeSMyCqps,
  plugin_6go_o4fa5t_4JC0e3EIE8wiKU6QDqrqQqCocEaYGMxk,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  ssg_detect_knOYSFrV0sEcIFAOc3IYaJ_Bnv4DRNSVYu_yMsIrjzc
]