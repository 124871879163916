<script lang="ts" setup>
import {useLocalePath} from "#imports";

const localePath = useLocalePath();
const router = useRouter();

const props = defineProps({
  internalLinkTo: {type: String, required: true},
  url: {type: String, required: true},
  alt: {type: String, required: true},
  text: {type: String, required: true},
  provider: {type: String, required: false},
  quality: {type: String, required: false},
  useHalf: {type: Boolean, required: false},
});
</script>

<template>
  <nuxt-link :to=localePath(internalLinkTo) class="details-side-image-container" :class="{ half: useHalf }">
    <div class="gradient-overlay"></div>

    <nuxt-img :alt="alt" quality="80" :src="url" width="800px" height="800px" class="details-side-image" format="webp"/>

    <h2 class="details-image-text">»{{ text }}</h2>
  </nuxt-link>
</template>

<style lang="sass" scoped>
.gradient-overlay
  position: absolute
  width: 100%
  height: 100%
  background-color: transparent !important
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0, rgba(0, 0, 0, 0) 50%)
  transition: all 200ms ease-in-out

  &:hover
    opacity: 0

.details-image-text
  font-size: 1.2rem
  color: rgba(255, 255, 255, 0.8)
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.7)
  position: absolute
  bottom: 5%
  left: 5%

.details-side-image
  overflow: hidden
  cursor: pointer
  height: 100%
  object-fit: cover
  width: 100%
  transition: all 300ms ease

.details-side-image-container
  border-radius: 8px
  position: relative
  overflow: hidden
  transition: all 300ms ease
  cursor: pointer
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1)
  margin: 0
  padding: 0
  height: 100%

.half
  width: 50%
</style>
