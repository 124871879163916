
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as newsDP_WXM_4YAJIFDG9XLtzxpTjkSgEFaVqkwGmWs0FfJ0Meta } from "/app/pages/news.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as testarQtBH0jtcyrCZsfXd_457EMn5eWExuRtzf4NcS7OJPWoMeta } from "/app/pages/dev/test.vue?macro=true";
import { default as indexoEI1yxwVOGzliN7ij1i2ZSBxZ8Xc6AaaU4F7ogT5IwsMeta } from "/app/pages/refuge/index.vue?macro=true";
import { default as storyGSLbp4JkQTRz3FWDPdr7fe_45Hd2Ktld3FVKFH5d0TN_45cMeta } from "/app/pages/refuge/story.vue?macro=true";
import { default as photosvc3UMwwGOwCOBdUgr3_45igIjY_m86n5xoWytTfTdv56IMeta } from "/app/pages/refuge/photos.vue?macro=true";
import { default as videosEXnpwuZgvKW_459lagyM6l1khVrUIDyKjWsTxZBvjshF0Meta } from "/app/pages/refuge/videos.vue?macro=true";
import { default as menuak9w2_45MbUu4UNeadisKm_45kC3MTUks_pSTGsT62EyA5wMeta } from "/app/pages/services/menu.vue?macro=true";
import { default as bookingY6mjP0nFmYufnq5LCqUc2kv5vvAG3Qo63rjBZSkRRfUMeta } from "/app/pages/refuge/booking.vue?macro=true";
import { default as camerasgFRQckcIhjuFKB6E79myhQrP3ye0pam5ahmCJyHknVoMeta } from "/app/pages/refuge/cameras.vue?macro=true";
import { default as indexjRtHzhS_dpsR_NlfsCCACFtIpspax8jcPZapdKX7vF0Meta } from "/app/pages/services/index.vue?macro=true";
import { default as approach8RT55gHCJ2LrRuoOxQVpsVVR2ok2SbxqDjLFjgIYGCEMeta } from "/app/pages/refuge/approach.vue?macro=true";
import { default as index3IAlOUPjudokwLk5fHMNX_2ErptX4undnQbtukBLvLEMeta } from "/app/pages/activities/index.vue?macro=true";
import { default as alpineQqfZq80ntS_wzKd_MRVv7HF_45_45r6BOKStnlch59JdpqsMeta } from "/app/pages/activities/alpine.vue?macro=true";
import { default as accommodationEBn2w8l3yUmHDNZEl12X_mEOS7sBTCifYjcsq2MPPsUMeta } from "/app/pages/services/accommodation.vue?macro=true";
import { default as hiking_45trailsnVUzpGRE0Dh2szWc4_U7LnTnGrgS3dPGVhx_45Bm8_45ZywMeta } from "/app/pages/activities/hiking-trails.vue?macro=true";
export default [
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/app/pages/news.vue")
  },
  {
    name: "news___el",
    path: "/news",
    component: () => import("/app/pages/news.vue")
  },
  {
    name: "news___de",
    path: "/de/news",
    component: () => import("/app/pages/news.vue")
  },
  {
    name: "news___fr",
    path: "/fr/news",
    component: () => import("/app/pages/news.vue")
  },
  {
    name: "news___it",
    path: "/it/news",
    component: () => import("/app/pages/news.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___el",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "dev-test___en",
    path: "/en/dev/test",
    component: () => import("/app/pages/dev/test.vue")
  },
  {
    name: "dev-test___el",
    path: "/dev/test",
    component: () => import("/app/pages/dev/test.vue")
  },
  {
    name: "dev-test___de",
    path: "/de/dev/test",
    component: () => import("/app/pages/dev/test.vue")
  },
  {
    name: "dev-test___fr",
    path: "/fr/dev/test",
    component: () => import("/app/pages/dev/test.vue")
  },
  {
    name: "dev-test___it",
    path: "/it/dev/test",
    component: () => import("/app/pages/dev/test.vue")
  },
  {
    name: "refuge___en",
    path: "/en/refuge",
    component: () => import("/app/pages/refuge/index.vue")
  },
  {
    name: "refuge___el",
    path: "/refuge",
    component: () => import("/app/pages/refuge/index.vue")
  },
  {
    name: "refuge___de",
    path: "/de/refuge",
    component: () => import("/app/pages/refuge/index.vue")
  },
  {
    name: "refuge___fr",
    path: "/fr/refuge",
    component: () => import("/app/pages/refuge/index.vue")
  },
  {
    name: "refuge___it",
    path: "/it/refuge",
    component: () => import("/app/pages/refuge/index.vue")
  },
  {
    name: "refuge-story___en",
    path: "/en/refuge/story",
    component: () => import("/app/pages/refuge/story.vue")
  },
  {
    name: "refuge-story___el",
    path: "/refuge/story",
    component: () => import("/app/pages/refuge/story.vue")
  },
  {
    name: "refuge-story___de",
    path: "/de/refuge/story",
    component: () => import("/app/pages/refuge/story.vue")
  },
  {
    name: "refuge-story___fr",
    path: "/fr/refuge/story",
    component: () => import("/app/pages/refuge/story.vue")
  },
  {
    name: "refuge-story___it",
    path: "/it/refuge/story",
    component: () => import("/app/pages/refuge/story.vue")
  },
  {
    name: "refuge-photos___en",
    path: "/en/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue")
  },
  {
    name: "refuge-photos___el",
    path: "/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue")
  },
  {
    name: "refuge-photos___de",
    path: "/de/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue")
  },
  {
    name: "refuge-photos___fr",
    path: "/fr/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue")
  },
  {
    name: "refuge-photos___it",
    path: "/it/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue")
  },
  {
    name: "refuge-videos___en",
    path: "/en/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue")
  },
  {
    name: "refuge-videos___el",
    path: "/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue")
  },
  {
    name: "refuge-videos___de",
    path: "/de/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue")
  },
  {
    name: "refuge-videos___fr",
    path: "/fr/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue")
  },
  {
    name: "refuge-videos___it",
    path: "/it/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue")
  },
  {
    name: "services-menu___en",
    path: "/en/services/menu",
    component: () => import("/app/pages/services/menu.vue")
  },
  {
    name: "services-menu___el",
    path: "/services/menu",
    component: () => import("/app/pages/services/menu.vue")
  },
  {
    name: "services-menu___de",
    path: "/de/services/menu",
    component: () => import("/app/pages/services/menu.vue")
  },
  {
    name: "services-menu___fr",
    path: "/fr/services/menu",
    component: () => import("/app/pages/services/menu.vue")
  },
  {
    name: "services-menu___it",
    path: "/it/services/menu",
    component: () => import("/app/pages/services/menu.vue")
  },
  {
    name: "refuge-booking___en",
    path: "/en/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue")
  },
  {
    name: "refuge-booking___el",
    path: "/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue")
  },
  {
    name: "refuge-booking___de",
    path: "/de/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue")
  },
  {
    name: "refuge-booking___fr",
    path: "/fr/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue")
  },
  {
    name: "refuge-booking___it",
    path: "/it/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue")
  },
  {
    name: "refuge-cameras___en",
    path: "/en/refuge/cameras",
    component: () => import("/app/pages/refuge/cameras.vue")
  },
  {
    name: "refuge-cameras___el",
    path: "/refuge/cameras",
    component: () => import("/app/pages/refuge/cameras.vue")
  },
  {
    name: "refuge-cameras___de",
    path: "/de/refuge/cameras",
    component: () => import("/app/pages/refuge/cameras.vue")
  },
  {
    name: "refuge-cameras___fr",
    path: "/fr/refuge/cameras",
    component: () => import("/app/pages/refuge/cameras.vue")
  },
  {
    name: "refuge-cameras___it",
    path: "/it/refuge/cameras",
    component: () => import("/app/pages/refuge/cameras.vue")
  },
  {
    name: "services___en",
    path: "/en/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services___el",
    path: "/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services___de",
    path: "/de/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services___fr",
    path: "/fr/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services___it",
    path: "/it/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "refuge-approach___en",
    path: "/en/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue")
  },
  {
    name: "refuge-approach___el",
    path: "/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue")
  },
  {
    name: "refuge-approach___de",
    path: "/de/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue")
  },
  {
    name: "refuge-approach___fr",
    path: "/fr/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue")
  },
  {
    name: "refuge-approach___it",
    path: "/it/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue")
  },
  {
    name: "activities___en",
    path: "/en/activities",
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "activities___el",
    path: "/activities",
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "activities___de",
    path: "/de/activities",
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "activities___fr",
    path: "/fr/activities",
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "activities___it",
    path: "/it/activities",
    component: () => import("/app/pages/activities/index.vue")
  },
  {
    name: "activities-alpine___en",
    path: "/en/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue")
  },
  {
    name: "activities-alpine___el",
    path: "/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue")
  },
  {
    name: "activities-alpine___de",
    path: "/de/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue")
  },
  {
    name: "activities-alpine___fr",
    path: "/fr/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue")
  },
  {
    name: "activities-alpine___it",
    path: "/it/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue")
  },
  {
    name: "services-accommodation___en",
    path: "/en/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue")
  },
  {
    name: "services-accommodation___el",
    path: "/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue")
  },
  {
    name: "services-accommodation___de",
    path: "/de/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue")
  },
  {
    name: "services-accommodation___fr",
    path: "/fr/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue")
  },
  {
    name: "services-accommodation___it",
    path: "/it/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue")
  },
  {
    name: "activities-hiking-trails___en",
    path: "/en/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue")
  },
  {
    name: "activities-hiking-trails___el",
    path: "/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue")
  },
  {
    name: "activities-hiking-trails___de",
    path: "/de/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue")
  },
  {
    name: "activities-hiking-trails___fr",
    path: "/fr/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue")
  },
  {
    name: "activities-hiking-trails___it",
    path: "/it/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue")
  }
]