<script lang="ts" setup>
import {IconPosition} from "~/enums/IconPositionEnum";

const localePath = useLocalePath();

const props = defineProps(
    {
      text: {type: String, required: true},
      redirect: {type: String, required: true},
      icon: {type: String, required: false},
      target: {type: String, required: false, default: '_self'},
      iconPosition: {type: String, required: false, default: IconPosition.Left},
    }
)

const shouldShowIcon = computed(() => props.icon)
</script>

<template>
  <nuxt-link :to="localePath(redirect)" class="refuge-btn" :target="target">
    <Icon v-if="shouldShowIcon" :name="icon ?? ''" size="2rem"
          :class="iconPosition === IconPosition.Right && 'order-last'"/>
    {{ text }}
  </nuxt-link>
</template>

<style lang="sass" scoped>
$dark_background_color: rgb(30, 30, 30)
$hover_dark_gray: rgb(40, 40, 40)

.dark-mode .refuge-btn
  border: 1px solid white
  color: white

  &:hover
    background-color: white
    color: black
    border: 1px solid black

.refuge-btn
  display: flex
  justify-content: center
  align-items: center
  padding: 0 1rem
  font-weight: bold
  height: 4rem
  cursor: pointer
  transition: all 300ms ease-in-out
  border: 1px solid black
  text-decoration: none
  color: black
  width: fit-content
  min-width: 10ch

  &:hover
    color: white
    background-color: $dark_background_color

  .order-last
    order: 1

</style>
