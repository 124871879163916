<script setup lang="ts">
import {useRouter} from "#app";

const colorMode = useColorMode()

const router = useRouter();
const goBack = () => router.go(-1);
const goHome = () => router.push('/');
</script>

<template>
  <div class="error-container" @click="goHome">
    <div class="logo-container">
      <nuxt-img class="logo" src="LOGO_AGGLIKO_j6t0ou.svg" width="400" placeholder="placeholder"
                format="svg"></nuxt-img>
    </div>
    <h1>{{ $t('404') }}</h1>
    <h2>{{ $t('clickAnywhereToGoHome') }}</h2>

  </div>
</template>

<style scoped lang="sass">
.error-container
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  width: 100vw
  height: 100vh
  cursor: pointer
  margin: 0
  padding: 0
  text-align: center

.logo-container
  width: 40vw
  transition: all 300ms ease

@media (max-width: 480px)
  .error-container
    height: 70vh
  h1
    font-size: 1.5rem
  .logo-container
    display: flex
    width: 60vw
</style>
