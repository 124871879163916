<script lang="ts" setup>
import {useColorMode, useRouter} from "#imports";
import NavButton from "~/components/NavigationBar/NavButton.vue";
import {type ComputedRef, ref} from "vue";
import {ActivitiesDetails, EmptyDetails, ServicesDetails, TheRefugeDetails} from "~/data/NavDetails";
import {useWindowScroll} from "@vueuse/core";
import InternalRedirectButton from "~/components/Buttons/InternalRedirectButton.vue";

//todo?
enum NavStates {
  TopOfPageNoDetails,
  TopOfPageWithDetails,
  FloatingNoDetails,
  FloatingWithDetails
}

const colorMode = useColorMode();
const router = useRouter();
const {locale} = useI18n();
const logo_link = "https://res.cloudinary.com/dlct0dnwt/image/upload/v1681256171/refuge/LOGO_AGGLIKO_j6t0ou.svg";

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
const isDark: ComputedRef<boolean> = computed(() => colorMode.preference === 'dark');

function toggleTheme() {
  colorMode.preference = isDark.value ? 'light' : 'dark';
}

function goHome() {
  if (locale.value === 'el')
    router.push('/');
  else
    router.push(`/${locale.value}/`);
}

//#region Hovering

// Guarantees there will be details to show
const showDetails$ = computed(() => lastHoveredButton.value != '' || hoveringOverDetails.value)

// Dependencies
const hoveringOverButton = ref(false);
const lastHoveredButton = ref("");

// Guarantees details will be kept alive as long as it's being hovered
const hoveringOverDetails = ref(false);
const detailsMap = {
  refuge: TheRefugeDetails,
  services: ServicesDetails,
  activities: ActivitiesDetails
} as const;

function onHoverEnterFromButton(source: string) {
  lastHoveredButton.value = source;
  selectedDetails.value = detailsMap[source as keyof typeof detailsMap] || EmptyDetails;
  hoveringOverButton.value = source in detailsMap;
}

function onHoverExitFromButton() {
  hoveringOverButton.value = false;
}

// The sleep is in order to avoid unnecessary flickering when going from one button to the other
async function mouseEnteringNav() {
  await sleep(10);
  if (!hoveringOverButton.value) {
    hoveringOverButton.value = false;
    lastHoveredButton.value = "";
  }
}

async function onHoverExitFromDetails() {
  hoveringOverDetails.value = false;
  await sleep(10);
  if (!hoveringOverButton.value) {
    lastHoveredButton.value = "";
  }
}

//#endregion

// Refs
const selectedDetails = ref(EmptyDetails);

const {y} = useWindowScroll()
const atTopOfPage = computed(() => y.value === 0);

const route = useRoute();
watch(
    () => route.path,
    () => {
      hoveringOverButton.value = false;
      lastHoveredButton.value = "";
    }
)
</script>

<template>
  <div class="all-nav-container"
       :class="{ 'float' : !atTopOfPage, 'nav-when-hovering-details' : showDetails$, 'border-16' : !showDetails$, 'rounded-top' : atTopOfPage}">
    <div class="nav" @mouseover="mouseEnteringNav">
      <div class="nav-container">
        <div class="left">

          <div class="logo-image-container">
            <nuxt-img :src="logo_link" alt="Λογότυπο του Καταφυγίου" class="logo" @click="goHome"/>
          </div>

          <ul class="nav-buttons-list">
            <li>
              <NavButton :name="$t('Τhe Refuge')" navRoute="refuge" @hoverEnter="onHoverEnterFromButton"
                         @hoverExit="onHoverExitFromButton"/>
            </li>
            <li>
              <NavButton :name="$t('Services')" navRoute="services" @hoverEnter="onHoverEnterFromButton"
                         @hoverExit="onHoverExitFromButton"/>
            </li>
            <li>
              <NavButton :name="$t('Activities')" navRoute="activities" @hoverEnter="onHoverEnterFromButton"
                         @hoverExit="onHoverExitFromButton"/>
            </li>
            <li style="user-select: none">|</li>
            <li>
              <NavButton :name="$t('webCams')" navRoute="/refuge/cameras" @hoverEnter="onHoverEnterFromButton"
                         @hoverExit="onHoverExitFromButton"/>
            </li>
          </ul>

        </div>

        <div class="right">

          <InternalRedirectButton :text="$t('Book now')" redirect="/refuge/booking"
                                  style="height: 60%; border-radius: 32px"/>
          <LangSwitcher/>
          <div aria-label="theme-toggle-menu" class="theme-toggler" @click="toggleTheme">
            <div class="circle"></div>
          </div>
        </div>

      </div>

    </div>

    <!--  Details panel that opens when hovering over a button -->
    <Transition>
      <div v-show="showDetails$" class="details-panel transition"
           @mouseenter="hoveringOverDetails = true" @mouseleave="onHoverExitFromDetails">
        <DetailsView :key="selectedDetails.header" :details="selectedDetails"/>
      </div>
    </Transition>

  </div>


</template>

<style lang="sass" scoped>
$dark_background_color: rgb(30, 30, 30)

.nav-when-hovering-details
  border-radius: 0 !important

.border-16
  border-radius: 16px

.rounded-top
  border-radius: 16px 16px 0 0 !important

.logo-image-container
  height: 60px
  display: flex
  align-items: flex-end
  background-color: transparent
  color: transparent

.dark-mode .float
  transition: 300ms ease-in-out

.float
  position: fixed !important
  top: 0 !important
  transition: 300ms ease-out
  border-radius: 0 0 16px 16px

.dark-mode .all-nav-container
  background-color: rgb(35, 35, 35)

.all-nav-container
  position: absolute
  width: 70vw
  left: 50%
  top: 1%
  transform: translate(-50%, -1%)
  z-index: 100
  background-color: whitesmoke
  transition: all 300ms ease-in-out
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1)

.v-enter-active, .v-leave-active
  transition: opacity 1s ease-in-out

.v-enter-from, .v-leave-to
  opacity: 0

.transition
  position: relative
  display: inline-block

.dark-mode .details-panel
  background-color: rgb(35, 35, 35)
  color: #ebf4f1
  box-shadow: 0 16px 32px 0 rgba(20, 20, 20, 0.5)

.details-panel
  position: absolute
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  z-index: 1
  background-color: whitesmoke
  width: 100%
  transition: all 300ms ease-in-out
  height: 300px

  border-bottom-right-radius: 8px
  border-bottom-left-radius: 8px

.nav-container
  width: 100%
  display: flex
  justify-content: space-between
  font-family: 'Montserrat', sans-serif
  margin: 0
  color: black

.nav
  display: flex
  justify-content: center
  align-items: center
  margin: 0
  padding: 8px
  transition: all 300ms ease-in-out

.dark-mode .nav-buttons-list
  color: white

.nav-buttons-list
  display: flex
  margin: 0
  justify-content: space-between
  align-items: center
  list-style: none
  transition: all 300ms ease-in-out

.left
  display: flex
  min-width: 50%

.center
  display: flex
  justify-content: flex-start
  gap: 20px

.right
  display: flex
  gap: 10px
  justify-content: center
  align-items: center

.navButtonContainer
  position: relative
  display: inline-block
  font-size: 20px

.dark-mode .circle
  background-color: lightgray
  // This moves the whole thing
  translate: 150%

.circle
  position: relative
  width: 20px
  height: 20px
  border-radius: 50px
  background-color: darkgray
  transition: all 300ms ease-in-out

.dark-mode .theme-toggler
  background-color: rgb(50, 50, 50)
  box-shadow: inset 0 0 5px rgba(20, 20, 20, 0.6)

.theme-toggler
  display: flex
  align-items: center
  width: 50px
  height: 30px
  border-radius: 20px
  background-color: whitesmoke
  padding: 0 5px 0 5px
  cursor: pointer
  box-shadow: inset 0 0 5px rgba(20, 20, 20, 0.2)
  transition: all 300ms ease-in-out
  -webkit-tap-highlight-color: transparent

.logo
  transition: all 300ms ease-in-out
  height: 80%
  cursor: pointer
  aspect-ratio: 1

.theme-btn
  display: flex
  justify-content: center
  align-items: center
  border-radius: 10px
  height: 50px
  padding: 10px
  border: none
  cursor: pointer
  overflow: hidden
  font-size: 1.8rem
  width: fit-content

  &:hover
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2)

.dark-mode .theme-btn
  color: white
  background-color: rgb(40, 40, 40)

@media (max-width: 1280px)
  .nav-container
    width: 90vw
    padding: 10px 0 0 0

</style>
