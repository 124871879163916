import {NavDetails} from "~/types/NavDetails";

export const EmptyDetails = new NavDetails("", [], [], [], [], []);

export const TheRefugeDetails = new NavDetails(
    "About Us",
    ["Our Story", "Booking", "How to get here", "liveWebCams"],
    ["/refuge/story", "/refuge/booking", "/refuge/approach", "/refuge/cameras"],
    ["Photos", "Videos", "webCams"],
    ["https://res.cloudinary.com/dlct0dnwt/image/upload/v1684614474/refuge/outside/main_yjac0p.jpg", "https://res.cloudinary.com/dlct0dnwt/image/upload/v1684614475/refuge/outside/outside_15_v9wdcw.jpg", "https://res.cloudinary.com/dlct0dnwt/image/upload/v1743607742/refuge/snapshots/camera1/snapshot-camera1.jpg"],
    ["/refuge/photos", "/refuge/videos", "/refuge/cameras"],
);

export const ServicesDetails = new NavDetails(
    "Accommodation",
    ["Overnight Stay", "Camping", "Common Area", "Kitchen"],
    ["/services/accommodation", "/services/accommodation#camping", "/services/accommodation#common-area", "/services/accommodation#kitchen"],
    ["Menu", "Accommodation"],
    ["https://res.cloudinary.com/dlct0dnwt/image/upload/v1684618456/refuge/stock/food_jprilv.jpg", "https://res.cloudinary.com/dlct0dnwt/image/upload/v1684614498/refuge/inside/sleepingArea_2_bbkixo.jpg"],
    ["/services/menu", "/services/accommodation"],
    "Food & Drink",
    ["Menu"],
    ["/services/menu"],
);

export const ActivitiesDetails = new NavDetails(
    "Hiking",
    ["Hiking Trails"],
    ["/activities/hiking-trails"],
    ["Hiking", "Alpine"],
    ["https://res.cloudinary.com/dlct0dnwt/image/upload/v1684614486/refuge/activities/hiking_2_xipvwv.jpg", "https://res.cloudinary.com/dlct0dnwt/image/upload/v1684614489/refuge/activities/icecClimbing_5_c7zg7x.jpg"],
    ["/activities/hiking-trails", "/activities/alpine"],
    "Alpine",
    ["Winter School", "Ice Climbing", "Alpine Skiing"],
    ["/activities/alpine", "/activities/alpine#ice-climbing", "/activities/alpine#skiing"]
);
